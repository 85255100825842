<template>
  <div class="business_sub_page">
    <div class="empty_content">
      <div class="empty_el">
        <div class="empty_desc">
          暂无内容,敬请期待
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {}
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page{
  .empty_content{
    display: flex;
    margin: 3rem 0;
    justify-content: center;
    .empty_el{
      width: 80%;
      height: 300px;
      background: url('./images/empty.png') 0 0 no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .empty_desc{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 1rem;
        color: #535353;
        line-height: 1.4rem;
      }
    }
  }
}
</style>